<template>
  <div class="login-box">
    <div class="login-content">
      <div class="platTitle">
        <div class="platTitle_ch">{{ projectConfig.name }}</div>
        <div class="platTitle_en">
          xxxx&nbsp;E&nbsp;PingTai&nbsp;Manager&nbsp;System
        </div>
      </div>
      <div class="login-title">请登录</div>
      <div class="title-line"></div>
      <div class="title-border">
        <span class="title-border-one"></span>
        <span class="title-border-two"></span>
      </div>
      <!-- <div class="line1"></div>
      <div class="line2"></div> -->
      <div class="login-message">
        <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
          <el-form-item prop="accountNumberAndPhone">
            <el-input
              v-model.trim="ruleForm.accountNumberAndPhone"
              placeholder="账号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input
              v-model.trim="ruleForm.pwd"
              placeholder="密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="verification">
            <el-input
              v-model.trim="ruleForm.verification"
              placeholder="验证码"
              maxlength="4"
            ></el-input>
            <!-- 图形验证码框 -->
            <div class="form-verification" @click="getImgCode">
              <img :src="imageCode" alt="" />
            </div>
          </el-form-item>
        </el-form>
        <el-button
          class="login-button"
          type="primary"
          :loading="loginBtnDisabled"
          :disabled="loginBtnDisabled"
          @click="goToLogin"
          >登录</el-button
        >
        <!-- <div class="login-button" @click="goToLogin">登录</div> -->
        <div class="login-bottom">
          <div class="login-agreement">
            <span class="scroll"></span>我已阅读并同意<span class="user-rules"
              >《****用户协议》</span
            >
          </div>
          <!-- <div class="forget-pass">
            <img src="../../assets/wenhao.png"
              style=" display: block;width: 13px;height: 13px;margin-right: 5px;" alt=""/>忘记密码
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { getCode, login } from "../../api/login_v2";
import { projectConfig } from "@/commonConfig/project.js";
export default {
  data() {
    return {
      projectConfig,
      loginBtnDisabled: false,
      ruleForm: {
        verification: "", //验证码
        accountNumberAndPhone: "", //账户或手机号
        pwd: "", //密码
        uuid: "",
        permissionType: 1, //后台管理端
        accountType: "0", //0:用户名登录，1:手机号登录, 2:手机验证码登录
      },
      imageCode: "", //图形验证码
      rules: {
        accountNumberAndPhone: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        verification: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    window.addEventListener("keydown", this.handleKeyDown, true); //开启监听键盘按下事件
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
  mounted() {
    this.getImgCode();
  },

  methods: {
    handleKeyDown() {
      let key = null;
      if (window.event === undefined) {
        key = e.keyCode;
      } else {
        key = window.event.keyCode;
      }
      if (key === 13) {
        //触发的事件
        this.goToLogin();
      }
    },
    /* 获取图片验证码 */
    getImgCode() {
      let uuid = uuidv4();
      this.ruleForm.uuid = uuid;
      getCode({
        uuid: uuid,
      }).then((res) => {
        if (res.code == 1) {
          let data = res.data;
          this.imageCode = data.imageCode;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    goToLogin() {
      this.$refs["ruleForm"].validate((volid) => {
        if (volid) {
          this.loginBtnDisabled = true;
          login(this.ruleForm)
            .then((res) => {
              // console.log("****")
              // console.log(res);
              this.loginBtnDisabled = false;
              if (res.code == 1) {
                let data = res.data;

                this.$store.commit("access_token", data.sessionId);
                sessionStorage.setItem(
                  "userInfo",
                  JSON.stringify(data.userInfo)
                );
                sessionStorage.setItem(
                  "loginUserMenu",
                  JSON.stringify(data.loginUserMenu)
                );
                // localStorage.setItem("imageRootPath", data.imageRootPath);
                let redirect = this.$route.query.redirect;
                if (redirect) {
                  console.log(redirect);
                  this.$router.push(redirect);
                } else {
                  this.$router.push("/home");
                }
              } else if (res.code == 100002) {
                //验证码错误
                this.$message.error(res.msg);
                this.ruleForm.verification = "";
                this.getImgCode();
              } else if (res.code == 100003) {
                //用户不存在
                this.$message.error(res.msg);
                this.ruleForm.accountNumberAndPhone = "";
                this.ruleForm.verification = "";
                this.ruleForm.pwd = "";
                this.getImgCode();
              } else if (res.code == 100004) {
                this.$message({
                  message: res.msg,
                  type: "error",
                  duration: 1000,
                });
                this.ruleForm.pwd = "";
                this.ruleForm.verification = "";
              } else {
                this.ruleForm.verification = "";
                this.$message.error(res.msg);
                /* 登录失败重新刷新验证码 */
                this.getImgCode();
              }
              this.$refs["ruleForm"].clearValidate;
            })
            .catch((e) => {
              this.loginBtnDisabled = false;
              this.getImgCode();
              this.ruleForm.verification = "";
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../../assets/login_v2/login_bg.png);
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  min-width: 1290px;
}
.platTitle {
  position: absolute;
  right: 120%;
  top: 45%;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  width: 360px;
  .platTitle_ch {
    font-size: 34px;
    height: 40px;
    line-height: 40px;
  }
  .platTitle_en {
    font-size: 18px;
    height: 40px;
    line-height: 40px;
  }
}

.login-content {
  // width: 500px;
  // height: 580px;
  background: #e5faf1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;

  .login-title {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #222222;
    text-align: center;
    padding-top: 13px;
  }

  .title-line {
    width: 30px;
    height: 2px;
    background: #14d393;
    margin: 0 auto;
  }

  .title-border {
    display: flex;
    margin-top: 15px;

    .title-border-one {
      display: block;
      width: 180px;
      height: 2px;
      background: #14d393;
    }

    .title-border-two {
      display: block;
      width: 180px;
      height: 2px;
      background: #a1b3ab;
    }
  }

  .login-message {
    // margin-top: 30px;
    // margin-left: 40px;
    margin: 20px;
  }
}

.form-verification {
  width: 100px;
  height: 40px;
  position: absolute;
  bottom: 10px;
  right: 0;
  border-radius: 5%;

  img {
    width: 100%;
    height: 100%;
    background: #14d393;
  }
}

// .login-button {
//   // width: 350px;
//   height: 46px;
//   background: #14d393;
//   font-size: 16px;
//   color: #ffffff;
//   text-align: center;
//   line-height: 46px;
//   border-radius: 6px;
//   margin-top: 25px;
//   cursor: pointer;
// }
.login-button {
  width: 330px;
  height: 46px;
  border-radius: 6px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  text-align: center;
}
.login-bottom {
  margin-top: 30px;
  font-size: 12px;
  display: flex;

  .login-agreement {
    color: #b4b4b4;
    display: flex;

    .scroll {
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #b4b4b4;
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  .forget-pass {
    color: #14d393;
    cursor: pointer;
    margin-left: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.user-rules {
  cursor: pointer;
}

/deep/.el-input__inner {
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0 10px;
  background-color: #e5faf1 !important;
  // &:visited {
  //   border-color: #e5faf1;
  //   background: transparent;
  // }
}

/deep/.el-input__inner:focus {
  border-color: #dcdfe6;
  // background: transparent;
}

/deep/.el-form-item__content {
  // margin-top: 15px;
}
</style>
